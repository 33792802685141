.profile-outer-div,
.profile-inner-div {
  height: 400px;
  max-width: 300px;
  margin: 1% auto;
  position: relative;
}
.profile-outer-div {
  perspective: 900px;
  perspective-origin: 50% calc(50% - 18em);
}
.profile-inner-div {
  margin: 0 auto;
  border-radius: 5px;
  font-weight: 400;
  color: #071011;
  font-size: 1rem;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.8, -0.4, 0.2, 1.7);
  transform-style: preserve-3d;
}
.profile-inner-div:hover .social-icon {
  opacity: 1;
  top: 0;
}
.profile-front,
.profile-back {
  position: relative;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}
.profile-front {
  cursor: pointer;
  height: 100%;
  background: #F7F1F3;
  backface-visibility: hidden;
  border-radius: 5px;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.profile-front__bkg-photo {
  position: relative;
  height: 150px;
  /* width: 300px; */
  background: url("../../images/back.jpg")
    no-repeat;
  background-size: cover;
  backface-visibility: hidden;
  overflow: hidden;
 
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.profile-front__bkg-photo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.profile-front__face-photo {
  position: relative;
  top: -60px;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #fff;
}
.profile-front__text {
  position: relative;
  top: -55px;
  margin: 0 auto;
  font-family: "Montserrat";
  font-size: 18px;
  backface-visibility: hidden;
}
.profile-front__text .front__text-header {
  font-weight: 700;
  font-family: "Oswald";
  text-transform: uppercase;
  font-size: 20px;
}
.profile-front__text .front__text-para {
  position: relative;
  top: -5px;
  color: #000;
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.profile-front__text .front-icons {
  position: relative;
  top: 0;
  font-size: 14px;
  margin-right: 6px;
  color: gray;
}
.profile-front__text .profile-front__text-hover {
  position: relative;
  top: 10px;
  font-size: 10px;
  color: #E90128;
  backface-visibility: hidden;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  border: 2px solid #E90128;
  padding: 8px 15px;
  border-radius: 30px;
  background: #E90128;
  color: #fff;
}
.profile-social-media-wrapper {
  font-size: 36px;
}
.profile-social-media-wrapper .social-icon {
  position: relative;
  top: 20px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0;
  color: #fff;
  transition: all 0.4s cubic-bezier(0.3, 0.7, 0.1, 1.9);
}
.profile-social-media-wrapper .social-icon:nth-child(1) {
  transition-delay: 0.6s;
}
.profile-social-media-wrapper .social-icon:nth-child(2) {
  transition-delay: 0.7s;
}
.profile-social-media-wrapper .social-icon:nth-child(3) {
  transition-delay: 0.8s;
}
.profile-social-media-wrapper .social-icon:nth-child(4) {
  transition-delay: 0.9s;
}
.fab {
  position: relative;
  top: 0;
  left: 0;
  transition: all 200ms ease-in-out;
}
.fab:hover {
  top: -5px;
}
