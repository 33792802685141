.sign-in {
  max-width: 35em;
  padding: 2em;
  margin: 2em auto;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 2%;
}

.sign-in form {
  max-width: 30em;
  margin: 0 auto;
}

.sign-in h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2ebe7;
}

.sign-in p {
  color: #bf1650;
}

.sign-in p::before {
  display: inline;
  content: "⚠ ";
}

.sign-in input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #e2ebe7;
}

.sign-in label {
  line-height: 2;
  text-align: left;
  display: block;
  /* margin-bottom: 13px; */
  color: white;
  font-size: 14px;
  font-weight: 200;
  color: #0e101c;
}

.sign-in a {
  font-size: 16px;
  font-weight: 200;
}

.sign-in button[type="submit"],
.sign-in input[type="submit"] {
  background-color: #E90128;
  color: #010606;
  border: none;
  margin-top: 2em;
  padding: 14px 48px;
  font-size: 16px;
  border-radius: 50px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.sign-in button[type="submit"]:hover,
.sign-in input[type="submit"]:hover {
  background-color: #E90128;
}

.sign-in button[type="submit"]:active,
.sign-in input[type="button"]:active,
.sign-in input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.sign-in input:disabled {
  opacity: 0.4;
}

.sign-in input[type="button"]:hover {
  transition: 0.3s all;
}

.sign-in button[type="submit"],
.sign-in input[type="button"],
.sign-in input[type="submit"] {
  -webkit-appearance: none;
}

.sign-in hr {
  margin-top: 3%;
}

.sign-in button {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
