.home-icon {
  font-size: 22px;
}

.home-icon:active {
  color: #F4032E;
}

.nav-icons {
  color: none;
  font-size: 25px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
 text-shadow:  1px 1px 2px red;;
}

.navbar a:hover {
  color: #F4032E;
}


