footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #000 !important;
  border-top: 1px solid #e7e7e7;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  color: #fff !important;
  display: flex;
 
  min-height: 97px;
 
  justify-content: center;
}

footer span {
  text-align: center;
  align-self: center;
  
}
